body {
    margin: 0;
    font-family: Jetbrains Mono,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
}

code {
    font-family: Jetbrains Mono,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace !important;
}


$darker-color: #060522;
$dark-color: #071230;
$light-color: #26334b;
$lighter-color: #cbdcff;

$theme-colors: (
        "primary": $darker-color,
        "secondary": $dark-color,
        "light": $lighter-color,
);


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.name {
    position: absolute;
    left: 50% ;
    top: 75%;
    transform: translate(-50%, -50%);
}


pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 0;
    line-break: normal;
    font-weight: bold;
}

.art {
    height: calc(100vh - 80px);
    width: 100%;
    position: relative;
}

.huge-text {
    font-size: min(1vw, 0.5rem);
    color: $lighter-color;
}

::-webkit-scrollbar {
    background: $darker-color;
    width: 20px;
    scrollbar-width: thin;

}

/* Track */
::-webkit-scrollbar-track {
    background: $dark-color;
    width: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $light-color;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $lighter-color;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

hr {
    border-top: thick dashed $light-color;
    margin: 0;
}

.gradiant {
    background: linear-gradient(0deg, $darker-color 0%, $dark-color 69%);
}

